<template>
  <div>
    <div class="title">快速安全登录</div>
    <div class="des">请使用微信扫描二维码进行登录</div>
    <div v-html="img" class="htm"></div>
  </div>
</template>

<script>
  import { setItem } from '@/assets/js/storage';
  export default{
    name: 'login',
    data(){
      return{
        img: '',
        salt: '',
        timer: null
      }
    },
    mounted(){
      this.qrcode()
    },
    methods:{
      async qrcode() {
        var res = await this.$api.get('index/setUrl');
        this.img = res.img;
        this.salt = res.salt;
        this.getToken();
      },
      getToken(){
        var that = this;
        this.timer = setInterval(() => {
          var params = {
            salt: that.salt
          }
          that.$api.get('index/weblogin', params).then(res => {
            if(res.error_code == 0){
              clearInterval(that.timer)
              setItem('token', res.uinfo.token);
              setItem('user', res.uinfo);
              that.$store.dispatch('setToken');
              that.$store.dispatch('setUser');
              that.$message.success('登录成功');
              that.$router.replace('/');
            }
          })
        }, 1000);
      }
    },
    destroyed () {
      clearInterval(this.timer);
    }
  }
</script>

<style lang="scss" scoped>
  .htm {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    margin-top: 40px;
    /deep/ img{
      width: 100%;
      height: 100%;
    }
  }
  .des{
    text-align: center;
    padding-top: 20px;
    font-size: 16px;
  }
  .title{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding-top: 100px;
  }
</style>
